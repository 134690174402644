<template>
    <div id="app">
        <v-app>
            <v-main class="bg" :class="classObject">
                <router-view />
            </v-main>
        </v-app>
    </div>
</template>

<script>
export default {
    name: "App",

    data() {
        return {
            backgroundZoom: false,
        };
    },
    computed: {
        classObject() {
            if (this.$vuetify.display.smAndUp) {
                return { bg_big: true };
            } else return { bg: true };
        },
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.bg {
    background: url("@/assets/background.webp") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.bg_big {
    background: url("@/assets/background.webp") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-size: 300%;
}
</style>
