import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";

loadFonts();
const app = createApp(App);
app.use(router).use(vuetify);
router
    .isReady()
    .then(() => {
        app.mount("#app");
    })
    .catch((err) => {
        console.error(err);
    });
